import React from "react"
import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import { Link } from "gatsby"
import {Helmet} from "react-helmet";
import FeatureStyle6 from "../components/Features/FeatureStyle6"

import Pleasanton from "../images/pleasanton-008.jpg"
import Dublin from "../images/dublin.jpg"
import SanRamon from "../images/sanramon.jpg"
import Newark from "../images/newark.jpg"
import Fremont from "../images/Fremont.jpg"
import Danville from "../images/Danville.jpg"
import SanJose from "../images/sanjose.jpg"
import Sunnyvale from "../images/Sunnyvale.jpg"
import MountainView from "../images/MountainView.jpg"
import PaloAlto from "../images/palo_alto.jpg"
import Cupertino from "../images/cupertino.jpg"
import Milpitas from "../images/milpitas.jpg"
import Saratoga from "../images/saratoga.jpg"
import RedwoodCity from "../images/redwood.jpg"
import DalyCity from "../images/dalycity.jpg"
import CastroValley from "../images/castrovalley.jpg"
import FosterCity from "../images/fostercity.jpg"
import SanMateo from "../images/sanmateo.jpg"
import Livermore from "../images/livermore.jpg"
import LasVegas from "../images/lasvegas.jpg"
import Orlando from "../images/orlando.jpg"
import Austin from "../images/austin.jpg"
import Campbell from "../images/campbell.jpg"
import Tracy from "../images/tracy.jpg"
import CallToAction2 from "../components/CallToAction/CallToAction2"
import Alameda from "../images/alameda.jpg"
import MenloPark from "../images/menlo_park.jpg"
import LosAltos from "../images/losaltos.jpg"
import LosGatos from "../images/losgatos.jpg"
import Atherton from "../images/atherton.jpg"
import WalnutCreek from "../images/walnutcreek.jpg"
import Millbrae from "../images/millbrae.jpg"
import Burlingame from "../images/burlingame.jpg"
import Hillsborough from "../images/hillsborough.jpg"
import Woodside from "../images/woodside.jpg"
import PortolaValley from "../images/portola_valley.jpg"
import Ads from "../images/ads.jpg"

import {Sparklines, SparklinesLine, SparklinesText} from 'react-sparklines';

const Homepage1 = (props) => {
  const {pathContext} = props;
  const cities_data = pathContext.city;

  const elements = []
  const newElements = []
  const inventoryElements = []
  const dayElements = []
  for (const city_data of cities_data) {
    const prices = [];
    const new_listings = [];
    const inventories = [];
    const days = [];
    for (const staticsItem of city_data.statics) {
      if (staticsItem.median_sales_price !== null) {
        prices.push(staticsItem.median_sales_price);
        new_listings.push(staticsItem.new_listing);
        inventories.push(staticsItem.inventory);
        days.push(staticsItem.average_sale_to_listing);
      } else {
      }
    }
    if (prices.length === 0) {
      continue;
    }
    elements.push(
      <div className="col-md-3" key={city_data.name}>
        <Link to={"/" + city_data.name}>
          <SparklinesText text={city_data.name}/>
          <Sparklines data={prices.slice(-12)}>
            <SparklinesLine />
          </Sparklines>
        </Link>
      </div>
    )
    newElements.push(
      <div className="col-md-3" key={city_data.name}>
        <Link to={"/" + city_data.name}>
          <SparklinesText text={city_data.name}/>
          <Sparklines data={new_listings.slice(-12)}>
            <SparklinesLine />
          </Sparklines>
        </Link>
      </div>
    )
    inventoryElements.push(
      <div className="col-md-3" key={city_data.name}>
        <Link to={"/" + city_data.name}>
          <SparklinesText text={city_data.name}/>
          <Sparklines data={inventories.slice(-12)}>
            <SparklinesLine />
          </Sparklines>
        </Link>
      </div>
    )
    dayElements.push(
      <div className="col-md-3" key={city_data.name}>
        <Link to={"/" + city_data.name}>
          <SparklinesText text={city_data.name}/>
          <Sparklines data={days.slice(-12)}>
            <SparklinesLine />
          </Sparklines>
        </Link>
      </div>
    )
  }

  return (
    <>
      <HeaderStyle1 />

      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>湾区热门城市房产 | Jing Xue | 湾区置业专家 湾区Top华人房产经纪 三谷第一华人房产经纪</title>
              <meta name="description" content="一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，南湾、东湾、三谷市场，手把手高效与您完成房产买卖服务" />
              <meta name="Keywords" content="湾区,三谷,华人房产经纪,南湾,东湾,湾区热门城市"></meta>
              <meta name="author" content="Jing Xue" />
              <link rel="canonical" href="https://www.jingxuehomes.com" />
          </Helmet>
      </div>
      {/* Start Features Section */}
      <section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="旧金山湾区深度解析" />
            </div>
          </div>
          <div className="row">
            <img src="https://res.cloudinary.com/realtoragents-us/image/upload/v1624477288/20190613104045243406952_p8kmsi.jpg" alt=""/>
          </div>
          <div className="row">
              <div className="post-text">
                  <p>旧金山湾区(San Francisco Bay Area)，也被称为“湾区”(The Bay Area)，包括旧金山湾四周的多个城市，分为北湾(North Bay)、旧金山市区(The City of San Francisco)、东湾(East Bay)、半岛(The Peninsula)、南湾(South Bay, San Jose and Silicon Valley)五个区域。</p>
              </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="南湾 (South Bay，San Jose and Silicon Valley)" />
            </div>
          </div>
          <div className="row">
              <div className="post-content">
                  <div className="post-text">
                      <p>旧金山湾南部也就是圣塔克拉拉谷地区，此地又被称为硅谷，是许多高科技公司的所在地。</p>
                  </div>
              </div>
          </div>
          <div className="row">
          <div className="col-md-4">
                    <FeatureStyle6
                        image={Sunnyvale}
                        title="Sunnyvale"
                        text="高素质的居民，干净明亮的街区，时尚多样的配套"
                        url="Sunnyvale"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={MountainView}
                        title="Mountain View"
                        text="从谷歌诞生地到硅谷核心区"
                        url="Mountain View"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={PaloAlto}
                        title="Palo Alto"
                        text="硅谷核心城市，加州最好的学区"
                        url="Palo Alto"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Cupertino}
                        title="Cupertino"
                        text="Apple总部，硅谷的心脏，顶级学区，湾区中国城"
                        url="Cupertino"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Saratoga}
                        title="Saratoga"
                        text="悠闲安静，临近硅谷，硅谷精英的后花园。"
                        url="Saratoga"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Campbell}
                        title="Campbell"
                        text="迅猛成长，拥有良好社区，硅谷新宠。"
                        url="Campbell"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={MenloPark}
                        title="Menlo Park"
                        text='湾区的新贵族城市'
                        url="Menlo Park"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={LosGatos}
                        title="Los Gatos"
                        text='深受欢迎的富人区，环境优美学区优质'
                        url="Los Gatos"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={LosAltos}
                        title="Los Altos"
                        text='最昂贵和最富有的城市之一，硅谷豪宅区'
                        url="Los Altos"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Atherton}
                        title="Atherton"
                        text='湾区的美国顶级豪宅区，硅谷大佬云集'
                        url="Atherton"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Ads}
                        title="Jing Xue"
                        text='湾区Top房产经纪'
                        url=""
                        hot={true}
                    />
                    </div>
          </div>
          <section>
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-5">
                        <CallToAction2 bgColor="#222" paddingClass="pad50" text={"我要卖房，请联系Jing Xue咨询"}/>
                    </div>
                </div>
            </div>
        </section>
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="东湾(East Bay)" />
            </div>
          </div>
          <div className="row">
            <div className="post-content">
                <div className="post-text">
                    <p>相较于湾区其他地方，东湾的房价比较便宜，但是气候却是最宜人的；没有旧金山的冷，也没有南湾的热。东湾的很多城市深受华人的青睐，尤其以Fremont最为吸引华裔。这里有湾区最好的学区之一Mission San Jose，也有湾区最受欢迎的爬山之地Mission Peak，Pleasanton Hill山上布满了远离城市喧嚣的豪宅，Livermore的outlets购物中心。</p>
                </div>
            </div>
          </div>
          
          <div className="row">
            
          <div className="col-md-4">
                    <FeatureStyle6
                        image={Pleasanton}
                        title="Pleasanton"
                        text="旧金山东湾最佳学区，明星城市，华人眼中的“幸福屯”"
                        url="Pleasanton"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Dublin}
                        title="Dublin"
                        text="社区优美，发展快速，文化多元的明星之城。"
                        url="Dublin"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={SanRamon}
                        title="San Ramon"
                        text="东湾最安全的好学区，加州抚养孩子最安全的城市。"
                        url="San Ramon"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Newark}
                        title="Newark"
                        text="性价比高，交通方便，年轻工程师的首选安家之地。"
                        url="Newark"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Fremont}
                        title="Fremont"
                        text="整个湾区的中点，一流学区，全美生活最幸福的地方"
                        url="Fremont"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Danville}
                        title="Danville"
                        text="加州传统的好学区、富人区，最适合家庭居住城镇"
                        url="Danville"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Milpitas}
                        title="Milpitas"
                        text="越来越年轻化的城市，华人工程师的首选"
                        url="Milpitas"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={CastroValley}
                        title="Castro Valley"
                        text="地理位置佳、可负担的价位，周边环境风景宜人。"
                        url="Castro Valley"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Livermore}
                        title="Livermore"
                        text="地广人少，风景优美的酒庄，交通方便，房价亲民"
                        url="Livermore"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Alameda}
                        title="Alameda"
                        text="与世隔绝的滨水城市，又不失生活的便利"
                        url="Alameda"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={WalnutCreek}
                        title="Walnut Creek"
                        text='美丽富饶，以白人为主的雅致小城'
                        url="Walnut Creek"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={WalnutCreek}
                        title="Blackhawk"
                        text='美丽富饶，以白人为主的雅致小城'
                        url="Blackhawk"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Ads}
                        title="Jing Xue"
                        text='湾区Top房产经纪'
                        url=""
                        hot={true}
                    />
                    </div>
          </div>
          <section>
              <div className="row">
                  <div className="col-md-12">
                      <div className="mb-5">
                          <CallToAction2 bgColor="#222" paddingClass="pad50" text={"我要买房，请联系Jing Xue咨询"}/>
                      </div>
                  </div>
              </div>
          </section>
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="半岛(The Peninsula)" />
            </div>
          </div>
          <div className="row">
            <div className="post-content">
                <div className="post-text">
                    <p>介于旧金山和南湾的地区是旧金山半岛，当地常简称为“半岛”(The Peninsula)。这地区由圣马刁县的数个中小型城市和近郊社区和圣塔克拉拉县西北部份所组成，也包含数个太平洋岸边的城镇。2013年全美房价十大右邮编，仅半岛就占3席，且高居榜首前两位，分别是邮编为94027的Atherton和邮编为94022的Los Altos Hills。虽然有许多富有家庭居住在此，半岛居民的组成是相当多样化的。</p>
                </div>
            </div>
          </div>
          <div className="row">
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={RedwoodCity}
                        title="Redwood City"
                        text="百万美元的毫宅，无敌海景。"
                        url="Redwood City"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={DalyCity}
                        title="Daly City"
                        text=""
                        url="Daly City"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={FosterCity}
                        title="Foster City"
                        text="风景旖旎的岛居之城，华人置业移民的首选城市之一。"
                        url="Foster City"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={SanMateo}
                        title="San Mateo"
                        text="生活便利、交通方便、环境优美。"
                        url="San Mateo"
                        hot={false}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Millbrae}
                        title="Millbrae"
                        text="华人最密集的城市，被誉为美国的“摩纳哥”"
                        url="Millbrae"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Burlingame}
                        title="Burlingame"
                        text="湾区半岛的富人区，典型的中产阶级生活"
                        url="Burlingame"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Hillsborough}
                        title="Hillsborough"
                        text="湾区半岛的富人区，典型的中产阶级生活"
                        url="Hillsborough"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Woodside}
                        title="Woodside"
                        text="众多风投公司的所在地，宁静富裕的小镇，精英荟萃之地"
                        url="Woodside"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={PortolaValley}
                        title="Portola Valley"
                        text="美国最美城镇，富有又低调的小镇"
                        url="Portola Valley"
                        hot={true}
                    />
                    </div>
                    <div className="col-md-4">
                    <FeatureStyle6
                        image={Ads}
                        title="Jing Xue"
                        text='湾区Top房产经纪'
                        url=""
                        hot={true}
                    />
                    </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="旧金山市区(The City of San Francisco)" />
            </div>
          </div>
          <div className="row">
            <div className="post-content">
                <div className="post-text">
                    <p>San Francisco三面环水，环境优美，是一座山城。气候冬暖夏凉，阳光充足，年平均气温在20℃左右，被誉为“最受美国人欢迎的城市”。随着越来越多的高科技公司驻进旧金山，如今城市里许多大型项目都在如火如荼的建设中。</p>
                </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Features Section */}

      {/* Start Feature Section */}
      <section
        className="pad-t30 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="湾区各城市房价走势" />
            </div>
          </div>
          <div className="row">
            {elements}
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      {/* Start Feature Section */}
      <section
        className="pad-t30 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="湾区各城市新上市房屋走势" />
            </div>
          </div>
          <div className="row">
            {newElements}
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      {/* Start Feature Section */}
      <section
        className="pad-t30 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="湾区各城市库存走势" />
            </div>
          </div>
          <div className="row">
            {inventoryElements}
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      {/* Start Feature Section */}
      <section
        className="pad-t30 pad-b30 parallax"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="湾区各城市房屋平均售出天数走势" />
            </div>
          </div>
          <div className="row">
            {inventoryElements}
          </div>
        </div>
      </section>
      {/* End Feature Section */}
      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default Homepage1
